import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"


import ImgTarjetaPremiumSVG from "../images/cuenta-premium/tarjeta-premium.png"
import ImgTarjetaPremium from "../images/cuenta-premium/tarjeta-premium.gif"
import ImgEnvioDeTarjeta from "../images/cuenta-premium/envio-de-tarjeta.gif"
import ImgTarjetaVirtual from "../images/cuenta-premium/tarjeta-virtual.gif"


export const StyledText = styled.div`
  display: inline-block;
  max-width: 32rem;
`

export const StyledHeading = styled.h2`
  line-height: 1.125em;
  letter-spacing: -0.025em;
  color: rgb(25, 28, 31);
  font-size: 2.5rem;
  margin: 0px;

  @media (min-width: 1081px) {
    font-size: 3rem;
  }
`

export const StyledImage = styled.div`
  @media (max-width: 1080px) {
    margin-top: 6rem;
    max-height: 32rem;
  }
`

export const StyledAnimation = styled.div`
  margin: 0px auto;
  box-sizing: border-box;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const StyledSlide = styled.div`
  padding: 0px 3rem;
  margin: auto;
  max-width: 86rem;
  min-height: 100vh;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  flex-direction: row;

  @media (max-width: 519px) {
    padding: 0px 1.5rem;
    margin: 8.25rem 0px 12rem;
    height: auto;
    min-height: 0px;
    max-height: none;
  }

  @media (max-width: 1080px) {
    margin: 70px 0px;
    flex-direction: column-reverse;
    -moz-box-pack: center !important;
    justify-content: center !important;
    -moz-box-align: center !important;
    align-items: center !important;
    max-height: none;

    &:first-child {
      margin-top: auto;
    }

    & > div:first-child {
      margin-right: 0px;
    }

    & h2,
    & p {
      text-align: center;
    }
  }

  @media (min-width: 1081px) {
    ${ props => props.reverse ? "flex-direction: row-reverse" : "" };

    & ${StyledText} {
      ${ props => props.reverse ? "" : "margin-right: 4rem" };
    }

    & ${StyledImage} {
      ${ props => props.reverse ? "margin-right: 4rem" : "" };
    }
  }
`


const PremiumAccountPage = () => (
  <Layout>
    <SEO title="Cuenta premium" />
    <StyledSlide>
      <StyledText>
        <StyledHeading>KKO Premium</StyledHeading>
        <p>
          Con KKO, tienes la seguridad de saber exactamente dónde gastas tu dinero porque solo tu, tienes el control de tus finanzas.
        </p>
      </StyledText>
      <StyledImage>
        <StyledAnimation>
          <img src={ImgTarjetaPremium} alt="" />
        </StyledAnimation>
      </StyledImage>
    </StyledSlide>
    <StyledSlide reverse={true}>
      <StyledText>
        <StyledHeading>KKO Premium</StyledHeading>
        <p></p>
      </StyledText>
      <StyledImage>
        <StyledAnimation>
          <img src={ImgTarjetaPremiumSVG} alt="" />
        </StyledAnimation>
      </StyledImage>
    </StyledSlide>
    <StyledSlide>
      <StyledText>
        <StyledHeading>Envio de tarjeta express</StyledHeading>
        <p>
          Sabemos que tu tiempo es muy importante, por eso recibirás tu tarjeta dentro de 3 días hábiles de forma gratuita, en cualquier parte del mundo.
        </p>
      </StyledText>
      <StyledImage>
        <StyledAnimation>
          <img src={ImgEnvioDeTarjeta} alt="" />
        </StyledAnimation>
      </StyledImage>
    </StyledSlide>
    <StyledSlide reverse={true}>
      <StyledText>
        <StyledHeading>Tarjeta virtual desechable</StyledHeading>
        <p>
          Para mayor seguridad contra los fraudes en línea, te ofrecemos una tarjeta temporal gratis.<br />
          El número de tarjeta cambiará automáticamente después de cada transacción.
        </p>
      </StyledText>
      <StyledImage>
        <StyledAnimation>
          <img src={ImgTarjetaVirtual} alt="" />
        </StyledAnimation>
      </StyledImage>
    </StyledSlide>
  </Layout>
)

export default PremiumAccountPage
